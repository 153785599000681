<template>
  <layout-dashboard :title="$route.name">
    <v-container
      fluid
      class="fill-container-height"
    >
      <v-card>
        <v-card-title>{{ sectionTitle }}</v-card-title>
        <v-row class="pa-0 ma-4">
          <v-slider
            v-model="controller"
            class="mt-4"
            thumb-label="always"
            :max="oneYearPeriod"
            @end="onDateSubmit"
          />
        </v-row>

        <v-row
          v-if="payments && payments[controller]"
          justify="center"
        >
          <v-data-table
            :items="Object.entries(payments[controller])"
            :headers="headers"
          >
            <template
              v-slot:item.1="{ item: [, balance] }"
            >
              <div
                v-for="([currency, amount], index) in Object.entries(balance)"
                :key="index"
              >
                {{ currency.toUpperCase() }} - {{ amount }}
              </div>
            </template>
          </v-data-table>
        </v-row>
      </v-card>
    </v-container>
  </layout-dashboard>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import {oneYearPeriod} from '@/utils/macros/variables'

export default {
  name: "PaymentsStats",
  data: () => ({
    controller: 30,
    payments: {}
  }),
  beforeMount() {
    this.oneYearPeriod = oneYearPeriod
    this.sectionTitle = 'Choose date frame:'
    this.headers = [{text: 'Date', value: '0'}, {text: 'Summary', value: '1'}]
  },
  async mounted() {
    await this.fetchAllUsers()
    this.onDateSubmit(this.controller)

    if (this.getAllPayments) this.setLoader({visible: false})
  },
  computed: {
    ...mapGetters('statistics', ['getAllPaymentsPerDay', "getAllPayments"])
  },
  methods: {
    ...mapActions('statistics', ['fetchAllUsers', 'fetchPayments']),
    ...mapMutations('components', ['setLoader']),
    onDateSubmit(value) {
      this.setLoader({
        visible: true,
        message: 'Fetching payments'
      })

      this.fetchPayments({
        dateTime: value, cb: () => this.setLoader({
          visible: false,
        })
      })

      this.payments = JSON.parse(JSON.stringify(this.getAllPaymentsPerDay))
    }
  },
  watch: {
    getAllPayments() {
      this.onDateSubmit(this.controller)
    }
  }
}
</script>
